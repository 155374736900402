<template>
  <div class="error-no-access">
    <screen-no-access :label="$t('error.button.login')" />
  </div>
</template>

<script>
import ScreenNoAccess from '@/components/Screen/NoAccess.vue'

export default {
  name: 'Error400',
  components: {
    ScreenNoAccess,
  },
}
</script>

<style lang="scss" scoped>
.error-no-access {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
